<template>
  <div class="page album">
    <HeadBar class="album-head" :isShowTimesInfo="true" title="DJ现场"></HeadBar>
    <div class="album-content">
      <div class="album-content-left">
        <Mv 
          class="album-content-left-top"
          :song="song"
          :isPlaying="isPlaying"
          :isFullScreen="isFullScreen"
          :canUsePre="canUsePre"
          :canUseNext="canUseNext"
          @controlMvScreen="handleControlMvScreen"
          @controlNext="handleControlNext"
          @controlPlay="handleControlPlay"
          @controlPause="handleControlPause"
          @controlCanPlay="handleControlCanPlay"
          @controlEnded="handleControlEnded"
          @controlPre="handleControlPre"
        />
        <div class="album-content-left-bottom">
          <PromoBanner class="album-content-left-bottom-user" />
          <NavBar class="album-content-left-bottom-nav" :navlist="playlist"  @choose="handleClickPlayBanner" />
        </div>
      </div>

      <div class="album-content-right">
        <div class="album-content-right-top">
          <NavBar class="album-content-right-top-nav" :navlist="navlist" :selected="selected" @choose="handleClick" />
        </div>
        <div class="album-content-right-bottom">
          <SongList :dataList="dataList" :selectedSong="song" @play="handleClickPlay" @loadMore="handleLoadMore" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, watch, onBeforeMount, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { sendLog } from '@/directives/v-log/log'
import { Toast } from 'vant'
import eventBus from '@/utils/event-bus'
import useLoading from '@/composables/useLoading'
import useVip from '@/composables/useVip'
import Mv from './components/mv/index.vue'
import SongList from './components/songlist/index.vue'
import NavBar from './components/nav-bar/index.vue'
import PromoBanner from '@/components/nav-list/promo-banner.vue'
import { getAlbumSongList } from '@/service/album'
import { getSongM3U8 } from '@/service/song'
import get from 'lodash/get'
import { vipLogFrom } from '@/constants/index'


export default {
  name: 'Album',
  components: {
    Mv,
    SongList,
    NavBar,
    PromoBanner
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const { showLoading, hideLoading } = useLoading()
    const { showVipQrcode, vipQrcodeInstance } = useVip()
    const playlist1 = ref([])
    const playlist2 = ref([])
    const playlist = ref([
      {
        id: 5,
        text: '劲歌热舞',
        name: '劲歌热舞',
        image: 'https://qncweb.ktvsky.com/20240902/vadd/1997ce7050022994adf89ff1705f7870.png',
        verticalImage: 'https://qncweb.ktvsky.com/20240905/vadd/5706d4f0bd82b04babd4faad793825e0.png',
        isPlay: 2,
      },
      {
        id: 4,
        text: '狂欢电音',
        name: '狂欢电音',
        image: 'https://qncweb.ktvsky.com/20240902/vadd/7ecd5c15a15fb0f5573e9c4d0159b196.png',
        verticalImage: 'https://qncweb.ktvsky.com/20240905/vadd/87213d286f0d355f4c57a214afa4abe3.png',
        isPlay: 2,
      }
    ])

    const navlist = ref([
      {
        id: 3,
        text: '百听不厌',
        name: '百听不厌',
        image: 'https://qncweb.ktvsky.com/20240902/vadd/f90c23a5de505189cde2a47da40acf34.png',
      },
      {
        id: 1,
        text: '酒吧夜舞',
        name: '酒吧夜舞',
        image: 'https://qncweb.ktvsky.com/20240902/vadd/1bf2458caba17fe1ac186554e2472048.png',
      },
      {
        id: 2,
        text: '夜店极品',
        name: '夜店极品',
        image: 'https://qncweb.ktvsky.com/20240902/vadd/c32918017a986e0bd0c01a85635295bf.png',
      },
    ])

    // const canUsePre = ref(true)
    // const canUseNext = ref(true)

    const currentPlaylist = ref(playlist1.value)
    const currentPlaylistId = ref(1)
    const selected = ref({
      id: 3,
      name: '百听不厌',
      img: ''
    })
    let page = ref(1)
    const dataList = ref([])
    const song = ref({})

    let isPlaying = ref(true)
    let isFullScreen = ref(false)

    const isLogin = computed(() => !!store.state.userInfo.unionid)
    const isVip = computed(() => !!store.state.vipInfo.end_time)
    const userType = computed(() => store.state.userInfo.userType)

    // canUseNext
    const canUseNext = computed(() => {
      const index = currentPlaylist.value.findIndex(item => item.songid === song.value.songid)
      return index !== currentPlaylist.value.length - 1
    })

    // canUsePre
    const canUsePre = computed(() => {
      const index = currentPlaylist.value.findIndex(item => item.songid === song.value.songid)
      console.log('canUsePre', index !== 0)
      return index !== 0
    })

    const handleShowVipQrcode = async (fromType = '播放VIP歌曲') => {
      console.log('handleShowVipQrcode', fromType)
      handleControlPause()
      const log = isLogin.value ? `${fromType}VIP弹窗-车机` : `${fromType}VIP弹窗`
      // showVipQrcode({})

      showVipQrcode({
        fromType: 'vip-expired',
        log,
        isLogin: isLogin.value,
        fr: vipLogFrom.get(log) || 1748,
        closeCallback: () => {
          // eventBus.emit('handle-close-popUp')
          if (vipQrcodeInstance.value) {
            vipQrcodeInstance.value.hide()
          }
          sendLog({
            event_type: '10000~50000',
            event_name: 30169,
            event_data: {
              str1: '车载大碟页',
              str2: `${fromType}VIP弹窗`,
              str3: '关闭按钮点击',
              str4: 'click',
              str5: isLogin.value ? '已登录' : '未登录',
              str7: userType.value,
            },
          })
        }
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 30168,
        event_data: {
          str1: '车载大碟页',
          str2: `${fromType}VIP弹窗`,
          str3: `${fromType}VIP弹窗展示`,
          str4: 'show',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        }
      })
    }

    const checkVipStatus = (song) => {
      if (song.is_vip && !isVip.value) {
        console.log('checkVipStatus', song.is_vip, isVip.value)
        handleShowVipQrcode('播放VIP歌曲')
        return false
      }
      return true
    }

    const tabMap = {
      '酒吧夜舞': 30287,
      '夜店极品': 30289,
      '百听不厌': 30288,
    }
    const handleClick = async (item) => {
      console.log(item)
      sendLog({
        event_type: '10000~50000',
        event_name: tabMap[item.name] || 30287,
        event_data: {
          str1: '车载DJ',
          str2: item.name,
          str3: '点击',
          str4: 'click',
        }
      })
      page.value = 1
      dataList.value = []
      // change tab
      selected.value = item
      await fetchData()
      currentPlaylist.value = dataList.value
      currentPlaylistId.value = item.id
      // play
      song.value = dataList.value[0]
      playlist.value[0].isPlay = 2
      playlist.value[1].isPlay = 2
    }

    const handleClickPlay = (item) => {
      console.log(item)
      sendLog({
        event_type: '10000~50000',
        event_name: 30286,
        event_data: {
          str1: '车载DJ',
          str2: selected.value.name,
          str3: '点击',
          str4: 'click',
        }
      })
      sendLog({
        event_type: 'click',
        event_name: 121,
        event_data: {
            // song_id: song.songid,
            // song_name: song.music_name,
            // singer: song.singer,
            song_isvip: item.is_vip, // 增加歌曲vip上报标识
            // str3: state.userInfo.userType,
        }
      })

      if (!checkVipStatus(item)) return
      currentPlaylist.value = dataList.value
      currentPlaylistId.value = item.id
      song.value = item
      playlist.value[0].isPlay = 2
      playlist.value[1].isPlay = 2
    }

    const handleClickPlayBanner = (item) => {
      if (item.id === 5) {
        sendLog({
          event_type: '10000~50000',
          event_name: 30270,
          event_data: {
            str1: '车载DJ',
            str2: '酒吧热舞',
            str3: '点击',
            str4: 'click',
          },
        })
      }
      if (item.id === 4) {
        sendLog({
          event_type: '10000~50000',
          event_name: 30271,
          event_data: {
            str1: '车载DJ',
            str2: '夜店极品',
            str3: '点击',
            str4: 'click',
          },
        })
      } 
      // 如果当前点播的歌曲就来自当前点击的banner，则直接进入全屏
      if (currentPlaylistId.value === item.id) {
        isFullScreen.value = true
        return
      }
      console.log(item)
      currentPlaylist.value = item.id === 5 ? playlist1.value : playlist2.value
      currentPlaylistId.value = item.id
      // update playlist
      if (item.id === 5) {
        playlist.value[0].isPlay = 1
        playlist.value[1].isPlay = 2
      } else {
        playlist.value[0].isPlay = 2
        playlist.value[1].isPlay = 1
      }
      // play
      song.value = currentPlaylist.value[0]
      // 进入全屏
      isFullScreen.value = true
    }

    const handleLoadMore = async () => {
      console.log('load more')
      page.value += 1
      await fetchData()
    }

    const fetchData = async () => {
      console.log('fetch data')
      const res = await getAlbumSongList({
        id: selected.value.id,
        name: selected.value.name,
        page: page.value,
        size: 20
      })
      dataList.value = [...dataList.value, ...res.data]
      // console.log(dataList.value)
    }

    const handleControlMvScreen = () => {
      console.log('handleControlMvScreen')
      isFullScreen.value = !isFullScreen.value
    }

    const handleControlPre = () => {
      console.log('handleControlPre')
      const index = currentPlaylist.value.findIndex(item => item.songid === song.value.songid)
      if (index === 0) return
      const prevSong = currentPlaylist.value[index - 1]
      if (!checkVipStatus(prevSong)) return
      song.value = prevSong
    }

    const handleControlNext = () => {
      console.log('handleControlNext')
      const index = currentPlaylist.value.findIndex(item => item.songid === song.value.songid)
      if (index === currentPlaylist.value.length - 1) return
      const nextSong = currentPlaylist.value[index + 1]
      if (!checkVipStatus(nextSong)) return
      song.value = nextSong
    }

    const handleControlPlay = (func) => {
      console.log('handleControlPlay')
      isPlaying.value = true
      func && func()
    }

    const handleControlPause = (func) => {
      console.log('handleControlPause')
      isPlaying.value = false
      func && func()
    }

    const handleControlCanPlay = () => {
      console.log('handleControlCanPlay')
      hideLoading()
      isPlaying.value = true
    }

    const handleControlEnded = () => {
      console.log('handleControlEnded')
      handleControlNext()
    }

    const initPlaylist = async () => {
      const res = await getAlbumSongList({
        id: 5,
        name: '劲歌热舞',
        page: 1,
        size: 20
      })
      playlist1.value = res.data
      // console.log(playlist1.value)
      const res2 = await getAlbumSongList({
        id: 4,
        name: '狂欢电音',
        page: 1,
        size: 20
      })
      playlist2.value = res2.data
      // console.log(playlist2.value)  
    }

    onMounted(async () => {
      await fetchData()
      currentPlaylist.value = dataList.value
      currentPlaylistId.value = selected.value.id
      // play
      song.value = dataList.value[0]

      await initPlaylist()
    })

    watch(song, async (newVal, oldVal) => {
      if (newVal.songid == oldVal.songid) return
      // console.log('watch newVal', newVal)
      const res = await getSongM3U8(newVal.songid)
      // console.log(res)
      if (!get(res, 'm3u8.480', '')) {
        Toast('暂无该歌曲资源～')
        return
      }

      showLoading()

      // 更新歌曲信息
      song.value = {
        ...song.value,
        video_url: get(res, 'm3u8.480', ''),
        token: get(res, 'token', '')
      }
      console.log(song.value)
    })
    
    onBeforeMount(async () => {
      eventBus.emit('handle-video-muted')
      eventBus.emit('video-control-pause')
      // setTimeout(() => {
      //   eventBus.emit('handle-video-play');
      // }, 2000);
    })

    return {
      playlist,
      navlist,
      selected,
      dataList,
      isPlaying,
      song,
      isFullScreen,
      handleClick,
      handleClickPlay,
      handleClickPlayBanner,  
      handleLoadMore,
      handleControlMvScreen,
      handleControlNext,
      handleControlPlay,
      handleControlPause,
      handleControlCanPlay,
      handleControlEnded,
      handleControlPre,
      isLogin,
      isVip,
      userType,
      canUsePre,
      canUseNext
    }
  }
}
</script>

<style lang="stylus" scoped>
.album
  width 100%
  height 100vh
  padding-top 132px
  background url(https://qncweb.ktvsky.com/20240902/vadd/d7dca9b1f59836e6dd0ac1b4dfa508b8.png) no-repeat
  background-size 100% 100%
  background-position center
  .album-head
    background-color transparent
    height 132px
  .album-content
    width 100%
    height calc(100vh - 132px)
    display flex
    flex-direction row
    .album-content-left
      width 1000px
      height calc(100vh - 132px)
      .album-content-left-top
        display flex
        width 1000px
        height 553px
        margin-bottom 32px
      .album-content-left-bottom
        width 100%
        height 336px
        display flex
        justify-content center
        align-items center
        .album-content-left-bottom-nav
          width 492px
          height 336px
          ::v-deep .album-nav-bar-item
            width 234px !important
            height 336px !important
            // border-radius 10px
            &:nth-child(1)
              margin-right 24px
            .album-nav-bar-item-text
              bottom 32px
    .album-content-right
      flex 1
      margin-left 50px
      .album-content-right-top
        width 100%
        height 136px
        .album-content-right-top-nav
          width 100%
          height 136px
      .album-content-right-bottom
        width 100%
        height 730px
    @media screen and (max-width 1200px)
      flex-direction column
      .album-content-left
        width 100%
        height 1007px
        margin-bottom 32px
        .album-content-left-top
          width 100%
          height 631px
        .album-content-left-bottom
          width 100%
          height 336px
          justify-content space-between
          .album-content-left-bottom-user
            width 484px
            height 336px
            ::v-deep .login
              padding-top 32px
            ::v-deep .nickname
              line-height 1
            ::v-deep .vip-entry
              margin-top 16px
              width 452px !important
              height 92px !important
              transform unset !important
            ::v-deep .user-info
              transform unset !important
          .album-content-left-bottom-nav
            width 568px
            height 336px
            ::v-deep .album-nav-bar-item
              width 270px !important
              height 336px !important
      .album-content-right
        width 100%
        height 700px
        // margin-top 42px
        margin 0
        display flex
        flex-direction row
        justify-content space-between
        .album-content-right-top
          width 245px
          height 480px
          .album-content-right-top-nav
            width 245px
            height 480px
            display flex
            flex-direction column
            justify-content space-between
            @media screen and (max-width 1200px) and (min-height 1200px)
              height 474px
        .album-content-right-bottom
          width 100%
          height 600px
          margin-left 35px
</style>
