import http from '@/utils/httpV2'
import get from 'lodash/get'

export async function getAlbumSongList({ id, name, page, size = 20 }) {
  const res = await http.get('/stb/v2/dj/detail', {
    params: {
      id,
      name,
      page,
      size
    }
  })
  return {
    data: get(res, 'data.data_list', [])
  }
}