<template>
  <div class="album-songlist">
    <LoadMore
      class="album-songlist-list"
      ref="loadMoreRef"
      v-if="dataList.length"
      @load-more="fetchData"
      safeAreaHeight="15vw"
    >
      <div class="album-songlist-item" :class="{'active': item?.songid === selectedSong?.songid}" v-for="(item, index) in dataList" :key="index" @click="handleClick(item)">
        <div class="name">{{ item.music_name }}</div>
        <div class="desc">
          <div class="desc-item">{{ item.singer }}</div>
          <img v-if="item.is_vip" src="https://qncweb.ktvsky.com/20240902/vadd/29c9f8c58f7c4ec21b2283eb56c1a113.png" alt="">
        </div>
        <img class="play-icon" v-if="item?.songid === selectedSong?.songid" src="https://qncweb.ktvsky.com/20240905/vadd/fbfa15b820bb2130cd47f12143c16eaa.png" alt="">
      </div>
    </LoadMore>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store = useStore()
const route = useRoute()

const props = defineProps({
  dataList: {
    type: Array,
    default: () => []
  },
  selectedSong: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['loadMore', 'play'])

const handleClick = (item) => {
  // console.log(item)
  // play
  emit('play', item)
}

const fetchData = () => {
  emit('loadMore')
}

// watch(() => props.dataList, (newVal, oldVal) => {
//   console.log(newVal, oldVal)
// })

// onMounted(() => {
//   fetchData()
// })

</script>

<style lang="stylus" scoped>
.album-songlist
  width 100%
  height 100%
  &-list
    width 100%
    height 730px
    &::-webkit-scrollbar
      display none !important
    @media screen and (max-width 1200px)
      height 700px !important
  &-item
    width 100%
    height 146px
    display flex
    // align-items center
    flex-direction column
    justify-content center
    padding 0 10px
    box-sizing border-box
    border-bottom 3px solid rgba(255, 255, 255, 0.1)
    position relative
    .name
      font-size 28px
      color rgba(255, 255, 255, 0.9)
      max-width 700px
      overflow hidden
      text-overflow ellipsis
      white-space nowrap
    .desc
      display flex
      align-items center
      margin-top 12px
      // justify-content space-between
      font-size 22px
      color rgba(255, 255, 255, 1)
      &-item
        max-width 700px
        overflow hidden
        text-overflow ellipsis
        white-space nowrap
      img
        width 50px
        height 26px
        margin-left 12px
    .play-icon
      width 42px
      height 42px
      position absolute
      right 32px
      top 50%
      transform translateY(-50%)
    &.active
      .name, .desc-item
        color rgba(219, 174, 106, 1)
</style>