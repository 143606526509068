<template>
  <div class="album-mv" :class="{ 'fullscreen': isFullScreen }" @click="handleMvClick">
    <div class="album-mv-video">
      <!-- :class="isScale && 'scale'" -->
      
      <video-player
        v-if="song?.video_url"
        from="album"
        ref="videoPlayerRef"
        :autoplay="true"
        :startPosition="0"
        :src="song.video_url"
        :poster="''"
        :token="token"
        :muted="muted"
        @ended="handleVideoEnded"
        @canplay="handleCanPlay"
        @retryToken="handleRetryToken"
      />
    </div>
    <div class="album-mv-mini-control" v-if="!isFullScreen">
      <div class="mv-bar">
        <span>
          <div v-if="muted" @click.stop="handleMutedClick" class="mv-bar-btn">
            <img src="../../../../assets/images/mv-bar-muted.png" class="icon"/>
          </div>
          <div v-else @click.stop="handleMutedClick" class="mv-bar-btn">
            <img src="https://qncweb.ktvsky.com/20240904/vadd/598b6cf62b39de9fc3b9fdec5e28310f.png" class="icon"/>
          </div>
          <div @click.stop="handleVideoControlNext(1)" class="mv-bar-btn">
            <img src="../../../../assets/images/mv-bar-next.png" class="icon" />
          </div>
          <div
            v-if="!isPlaying"
            @click.stop="handleVideoControlPlay"
            class="mv-bar-btn"
          >
            <img src="../../../../assets/images/mv-bar-play.png" class="icon" />
          </div>
          <div v-else @click.stop="handleVideoControlPause(1)" class="mv-bar-btn">
            <img src="../../../../assets/images/mv-bar-pause.png" class="icon" />
          </div>
        </span>
        <div @click.stop="handleControlMvScreen(1)" class="mv-bar-btn">
          <img src="../../../../assets/images/mv-bar-screen.png" class="icon" />
        </div>
      </div>
    </div>
    <div v-if="isShowCenterControl && isFullScreen" class="album-mv-center-control" @click="handleControlClick">
      <div class="album-mv-center-control-back" @click.stop="handleControlMvScreen(2)">
        <img src="https://qncweb.ktvsky.com/20231207/vadd/72ff0b114ee2cb3153ce901af19bc813.png" class="icon" />
        <span>退出全屏</span>
      </div>
      <div class="album-mv-center-control-center">
        <div 
          class="album-mv-center-control-center-pre" 
          @click.stop="handleVideoControlPre"
          :class="{ 'disabled': !canUsePre }"
        >
          <img src="https://qncweb.ktvsky.com/20240903/vadd/c49c42018a9113fe21ef7a70058b3040.png" alt="">
          <span>上一首</span>
        </div>
        <div v-if="isPlaying" class="album-mv-center-control-center-play" @click.stop="handleVideoControlPause(2)">
          <img src="https://qncweb.ktvsky.com/20240903/vadd/f960e8f4d42266ceebe7a867e151367f.png" alt="">
          <span>暂停</span>
        </div>
        <div v-else class="album-mv-center-control-center-play" @click.stop="handleVideoControlPlay">
          <img src="https://qncweb.ktvsky.com/20240903/vadd/6788e639c0483da47aaa7e84c7475391.png" alt="">
          <span>播放</span>
        </div>
        <div 
          class="album-mv-center-control-center-next" 
          @click.stop="handleVideoControlNext(2)"
          :class="{ 'disabled': !canUseNext }"
        >
          <img src="https://qncweb.ktvsky.com/20240903/vadd/ce29888e55ebbc9ce855835761652bf6.png" alt="">
          <span>下一首</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits, onMounted, nextTick, onUnmounted } from 'vue'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import VideoPlayer from '@/components/video-player/index.vue';
import { getSongToken } from '@/service/song'
import get from 'lodash/get'
import { sendLog } from '@/directives/v-log/log'

const store = useStore()
const route = useRoute()

const emit = defineEmits(['controlMvScreen', 'controlNext', 'controlPlay', 'controlPause', 'controlPre', 'controlCanPlay'])

const props = defineProps({
  isPlaying: {
    type: Boolean,
    default: false
  },
  isFullScreen: {
    type: Boolean,
    default: false
  },
  song: {
    type: Object,
    default: () => {}
  },
  canUsePre: {
    type: Boolean,
    default: true
  },
  canUseNext: {
    type: Boolean,
    default: true
  },
})

const muted = ref(true)

let token = ref('')

let isShowCenterControl = ref(true)
let controlTimer = null

const showControl = () => {
  isShowCenterControl.value = true
  clearTimeout(controlTimer)
  controlTimer = setTimeout(() => {
    isShowCenterControl.value = false
  }, 6000)
}

const hideControl = () => {
  isShowCenterControl.value = false
  clearTimeout(controlTimer)
}

const handleMvClick = () => {
  sendLog({
    event_type: '10000~50000',
    event_name: 30280,
    event_data: {
      str1: '小屏mv',
      str2: '小屏mv',
      str3: '点击',
      str4: 'click',
    },
  })
  if (!isShowCenterControl.value) {
    showControl()
  }
}

const handleControlClick = (event) => {
  event.stopPropagation()
  hideControl()
}

const videoPlayerRef = ref(null)

// 添加新方法来控制视频播放器
const playVideo = () => {
  if (videoPlayerRef.value && videoPlayerRef.value.handleControlVideoPlay) {
    videoPlayerRef.value.handleControlVideoPlay()
  }
}

const pauseVideo = () => {
  if (videoPlayerRef.value && videoPlayerRef.value.handleControlVideoPause) {
    videoPlayerRef.value.handleControlVideoPause()
  }
}

const handleMutedClick = () => {
  sendLog({
    event_type: '10000~50000',
    event_name: 30282,
    event_data: {
      str1: '小屏mv',
      str2: muted.value ? '开启静音button' : '关闭静音button',
      str3: '点击',
      str4: 'click',
    },
  })
  muted.value = !muted.value
  if (videoPlayerRef.value) {
    videoPlayerRef.value.setMuted(muted.value)
  }
}

const handleControlMvScreen = (type) => {
  console.log('handleControlMvScreen', type)
  if (type === 1) {
    sendLog({
      event_type: '10000~50000',
      event_name: 30272,
      event_data: {
        str1: '车载DJ',
        str2: '小屏幕mv-全屏',
        str3: '点击',
        str4: 'click',
      },
    })
  }
  if (type === 2) {
    sendLog({
      event_type: '10000~50000',
      event_name: 30277,
      event_data: {
        str1: '车载DJ',
        str2: '全屏幕mv-退出',
        str3: '点击',
        str4: 'click',
      },
    })
  }
  emit('controlMvScreen')
}

const handleVideoControlPre = () => {
  console.log('handleVideoControlPre')
  sendLog({
    event_type: '10000~50000',
    event_name: 30278,
    event_data: {
      str1: '车载DJ',
      str2: '全屏幕mv-上一首',
      str3: '点击',
      str4: 'click',
    },
  })
  emit('controlPre')
}

const handleVideoControlNext = (type) => {
  console.log('handleVideoControlNext')
  if (type === 1) {
    sendLog({
      event_type: '10000~50000',
      event_name: 30273,
      event_data: {
        str1: '车载DJ',
        str2: '小屏幕mv-切歌',
        str3: '点击',
        str4: 'click',
      },
    })
  }
  if (type === 2) {
    sendLog({
      event_type: '10000~50000',
      event_name: 30275,
      event_data: {
        str1: '车载DJ',
        str2: '全屏幕mv-下一首',
        str3: '点击',
        str4: 'click',
      },
    })
  }
  emit('controlNext')
}

const handleVideoControlPlay = () => {
  console.log('handleVideoControlPlay')
  // 调用播放方法
  emit('controlPlay', () => {
    playVideo()
  })
}

const handleVideoControlPause = (type) => {
  console.log('handleVideoControlPause')
  if (type === 1) {
    sendLog({
    event_type: '10000~50000',
    event_name: 30274,
    event_data: {
      str1: '车载DJ',
      str2: '小屏幕mv-暂停',
      str3: '点击',
        str4: 'click',
      },
    })
  }
  if (type === 2) {
    sendLog({
      event_type: '10000~50000',
      event_name: 30276,
      event_data: {
        str1: '车载DJ',
        str2: '全屏幕mv-暂停',
        str3: '点击',
        str4: 'click',
      },
    })
  }
  pauseVideo()
}

const handleVideoEnded = () => {
  console.log('handleVideoEnded')
  emit('controlNext')
}

const handleCanPlay = () => {
  console.log('handleCanPlay')
  emit('controlCanPlay')
}

const handleRetryToken = async (error) => {
  const songData = await getSongToken()
  // const currServerTime = get(songData, 'serverTime', 0)
  token.value = get(songData, 'token', '')
  console.log('token', token.value)
};

watch(() => props.isPlaying, (newVal) => {
  if (newVal) {
    playVideo()
  } else {
    pauseVideo()
  }
})

watch(() => props.canUsePre, (newVal) => {
  console.log('canUsePre changed:', newVal)
})

onMounted(async () => {
  const songData = await getSongToken()
  token.value = get(songData, 'token', '')
  console.log('token', token.value)

  // 设置静音
  nextTick(() => {
    setTimeout(() => {
      if (videoPlayerRef.value) {
        videoPlayerRef.value.setMuted(muted.value)
      }
    }, 1000)
  })

  showControl() // 初始显示控制区域
})

onUnmounted(() => {
  clearTimeout(controlTimer)
})
</script>

<style lang="stylus" scoped>
.album-mv
  width 100%
  height 100%
  position relative
  
  @media screen and (max-width 1200px)
    ::v-deep .video-player video
      top 0 !important

  &.fullscreen
    position fixed
    top 0
    left 0
    width 100vw !important
    height 100vh !important
    z-index 9999
    @media screen and (max-width 1200px)
      ::v-deep .video-player video
        top 300px !important

  .album-mv-video
    width 100%
    height 100%
    border-radius 32px
    overflow hidden
    background-color #000

    .fullscreen &
      border-radius 0

  .album-mv-mini-control
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    .mv-bar
      position absolute
      display flex
      top 0
      left 0
      padding 24px
      justify-content space-between
      width 100%
      z-index 6
      span
        display flex
      .mv-bar-btn
        width 90px
        height 64px
        background rgba(17, 20, 24, 0.3)
        border-radius 12px
        display flex
        align-items center
        justify-content center

        @media screen and (max-width 1200px) and (min-height 1200px)
          width 99px
          height 70px

        &+.mv-bar-btn
          margin-left 12px
        .icon
          width 44px
          height 44px
          object-fit scale-down

          @media screen and (max-width 1200px) and (min-height 1200px)
            width 44px
            height 44px
  .album-mv-center-control
    width 100vw
    height 100vh
    position fixed
    top 0
    left 0
    right 0
    bottom 0
    display flex
    align-items center
    justify-content center
    z-index 10
    .album-mv-center-control-back
      position absolute
      top 36px
      left 36px
      width 334px
      height 120px
      background rgba(255, 255, 255, 0.1)
      border-radius 80px
      display flex
      align-items center
      justify-content center
      .icon
        width 36px
        height 36px
        object-fit scale-down
      span
        font-size 32px
        font-weight 300
        color #fff
        margin-left 40px
    .album-mv-center-control-center
      width 828px
      height 120px
      // background rgba(17, 20, 24, 0.3)
      // border-radius 12px
      display flex
      align-items center
      justify-content space-between
      @media screen and (max-width 1200px)
        position absolute
        bottom 729px
        left calc(50% - 414px)
      .album-mv-center-control-center-pre
        width 260px
        height 120px
        background rgba(255, 255, 255, 0.1)
        border-radius 80px
        display flex
        align-items center
        justify-content center
        .icon
          width 52px
          height 52px
          object-fit scale-down
        span
          font-size 36px
          font-weight 500
          color rgba(255, 255, 255, 0.8)
          margin-left 20px
        &.disabled
          opacity 0.3
          pointer-events none
      .album-mv-center-control-center-play
        width 260px
        height 120px
        background rgba(255, 255, 255, 0.1)
        border-radius 80px
        display flex
        align-items center
        justify-content center
        .icon
          width 52px
          height 52px
          object-fit scale-down
        span
          font-size 36px
          font-weight 500
          color rgba(255, 255, 255, 0.8)
          margin-left 20px
      .album-mv-center-control-center-next
        width 260px
        height 120px
        background rgba(255, 255, 255, 0.1)
        border-radius 80px
        display flex
        align-items center
        justify-content center
        .icon
          width 52px
          height 52px
          object-fit scale-down
        span
          font-size 36px
          font-weight 500
          color rgba(255, 255, 255, 0.8)
          margin-left 20px
</style>