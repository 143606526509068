<template>
    <div class="album-nav-bar-list">
      <div 
        class="album-nav-bar-item" 
        :class="{'album-nav-bar-item-active': item.id === selected?.id}" 
        v-for="item in navlist" 
        :key="item.id" 
        @click="() => handleClick(item)"
      >
        <img class="album-nav-bar-item-bg" :src="getImageSrc(item)" alt="">
        <div class="album-nav-bar-item-text">{{ item.text }}</div>
        <div class="album-nav-bar-item-play" v-if="item?.isPlay">
          <img src="https://qncweb.ktvsky.com/20240902/vadd/86fdbf4b84a36da1a7a0934b65e2c533.png" alt="" v-if="item?.isPlay === 1">
          <img src="https://qncweb.ktvsky.com/20240902/vadd/69a62d6b2fc1207ecba4eb0bbb1d2b22.png" alt="" v-if="item?.isPlay === 2">
        </div>
      </div>
    </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useShareBrowserSize } from '@/composables/sharedComposable'

const props = defineProps({
  navlist: {
    type: Array,
    default: () => []
  },
  selected: {
    type: Object,
    default: () => {}
  }
})

const emit = defineEmits(['choose'])

const store = useStore()
const route = useRoute()
const { browserType } = useShareBrowserSize()

const handleClick = (item) => {
  // console.log(item)
  emit('choose', item)
}

const isVertical = () => {
  return browserType.value !== 'landscape'
}

const getImageSrc = (item) => {
  return isVertical() && item.verticalImage ? item.verticalImage : item.image
}
</script>

<style lang="stylus" scoped>
.album-nav-bar
    &-list
      display flex
      flex-wrap wrap
      justify-content space-around
      align-items center
      width 100%
      height 100%
    &-item
      flex 1
      position relative
      display flex
      justify-content space-around
      align-items center
      @media screen and (max-width 1200px) and (min-height 1200px)
        width 245px !important
        height 136px !important
        flex none
      &-bg
        width 100%
        height 100%
        // background-size 100% 100%
        // background-repeat no-repeat
        // background-position center
      &-text
        width 100%
        height 33px
        font-size 28px
        color #fff
        text-align center
        line-height 33px
        position absolute
        bottom 18px
        left 0
      &-play
        width 60px
        height 60px
        background rgba(255, 255, 255, 0.16)
        border-radius 50%
        position absolute
        bottom 72px
        right 16px
        display flex
        justify-content center
        align-items center
      &-active
        border-radius 20px
        border 3px solid rgba(219, 174, 106, 1)
</style>